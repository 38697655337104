import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { handleCheckImgSize } from "../../../utils/utils";
import { artShopApi } from "../../../utils/api/api";
import { PaginationEx } from "../../common/pagination";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const ART_SHOP = process.env.REACT_APP_PATH_ART_SHOP;
const EXHIBITION = process.env.REACT_APP_PATH_EXHIBITION;
const THUMBNAIL = process.env.REACT_APP_PATH_THUMBNAIL;

const PAGE_SIZE = 9;

const ArtShopList = ({ filter }) => {
  const history = useHistory();
  const sectionPath = filter.board_sc_cd === 1 ? EXHIBITION : ART_SHOP; // 1:Original 2:Poster 3:Goods

  const [datas, setDatas] = useState([]);
  const [totalDataCnt, setTotalDataCnt] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevBoardSection, setPrevBoardSection] = useState(filter.board_sc_cd);

  useEffect(() => {
    const { board_sc_cd, order } = filter;

    /** 현재 페이지에서 tab 이동 시 currentPage 초기화 */
    if (board_sc_cd !== prevBoardSection) {
      setCurrentPage(1);
      setPrevBoardSection(board_sc_cd);
    }

    getArtshopList(currentPage, board_sc_cd, order);
  }, [filter, currentPage, prevBoardSection]);

  /** GET 아트샵 상품 리스트 */
  const getArtshopList = (page, boardSection, listOrder) => {
    if (boardSection === 1) { // Original
      artShopApi.getExhibitList(page, PAGE_SIZE, listOrder)
        .then((response) => {
          const { status, data } = response;

          if (status === 200) {
            setDatas(data.data);
            setTotalDataCnt(data.count);
          }
        })
        .catch(() => {
          setDatas(0);
          setTotalDataCnt([]);
        })
    } else if (boardSection === 2 || boardSection === 3) { // Poster, Goods
      artShopApi.getArtShopList(page, PAGE_SIZE, boardSection, listOrder)
        .then((response) => {
          const { status, data } = response;

          if (status === 200) {
            setDatas(data.data);
            setTotalDataCnt(data.count);
          }
        })
        .catch(() => {
          setDatas(0);
          setTotalDataCnt([]);
        })
    }
  }

  /** 현재 페이지 클릭 이벤트 */
  const handlePageChange = (value) => {
    setCurrentPage(value);
  }

  return (
    <>
      {
        datas.length === 0
          ? <p className="no-data">상품이 존재하지 않습니다.</p>
          : <ul className="art-list prize">
            {
              datas && datas.map(post => (
                <li
                  key={post.board_se}
                  onClick={() => history.push(`${routes.artshopDetail}/${filter.board_sc_cd}/${post.artist_se}/${post.board_se}`)}
                >
                  <div className={post.isSoldOut ? "frame sold-out" : "frame"}>
                    <span>
                      <img
                        onLoad={handleCheckImgSize}
                        src={IMAGE_URL + sectionPath + THUMBNAIL + post.thumbnail_lk}
                        alt={post.thumbnail_lk}
                      />
                    </span>
                    {
                      post.isSoldOut ?
                        <span className="ic">SOLD<br />OUT</span>
                        :
                        ""
                    }
                  </div>
                  <dl>
                    <dt>
                      <span className="tag">
                        {post.badge_new && <span className="new">NEW</span>}
                        {post.badge_best && <span className="best">BEST</span>}
                        {post.badge_sale && <span className="sale">SALE</span>}
                      </span>
                      {post.board_tt}
                    </dt>
                    <dd>
                      {
                        post.cancel_price_mo ?
                          <>
                            <del className="cancel-line">{post.cancel_price_mo.toLocaleString()}</del>
                            {post.price_mo.toLocaleString()}
                          </>
                          :
                          post.isPriceDisplayed ? post.price_mo.toLocaleString() : "가격문의"
                      }
                    </dd>
                  </dl>
                </li>
              ))
            }
          </ul>
      }
      {
        totalDataCnt > 0 &&
        <PaginationEx
          dataCount={totalDataCnt}
          activePage={currentPage}
          itemsCountPerPage={PAGE_SIZE}
          pageRangeDisplayed={10}
          onChange={handlePageChange}
          hideFirstLastPages={true}
        />
      }
    </>
  )
}

ArtShopList.propTypes = {
}

export default ArtShopList