import React from 'react';
import Header from "../components/common/header";
import Footer from "../components/common/footer";

const LayoutGallery = ({ children }) => {
  const currentPathname = children.props.location.pathname;

  return (
    <div id='wrap'>
      <div id="container">
        <div id="content">
          <Header url={currentPathname}/>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
}
// children.props.match.path
export default LayoutGallery;