import React, { useState } from "react";
import { Switch } from "react-router";
import { routes, components } from "./routes/routes";
import { BrowserRouter as Router } from "react-router-dom";
import LayoutGallery from "./layouts/LayoutGallery";
import LayoutHome from "./layouts/LayoutHome";
import LayoutError from "./layouts/LayoutError";
import AppRoute from "./layouts/AppRoute";

function App() {
  const [firstLoading, setLoading] = useState(false);

  return (
    <Router>
      <Switch>
        {/* 전시작품 */}
        <AppRoute
          path={routes.exhibition}
          component={components.exhibition}
          layout={LayoutGallery}
        />
        <AppRoute
          path={routes.exhibitionDetail + "/:exhibits_se"}
          component={components.exhibitionDetail}
          layout={LayoutGallery}
        />
        {/* 아트샵 */}
        <AppRoute
          path={routes.artshop}
          component={components.artshop}
          layout={LayoutGallery}
        />
        <AppRoute
          path={
            routes.artshopDetail + "/:board_sc/:artist_se/:board_se"
          }
          component={components.artshopDetail}
          layout={LayoutGallery}
        />
        {/* 공지사항 */}
        <AppRoute
          path={routes.notice}
          component={components.notice}
          layout={LayoutGallery}
        />
        <AppRoute
          path={routes.noticeDetail + "/:board_se"}
          component={components.noticeDetail}
          layout={LayoutGallery}
          exact={true}
        />
        {/* 아이갤러리 */}
        <AppRoute
          path={routes.igallery}
          component={components.igallery}
          layout={LayoutGallery}
        />
        {/* 오시는길 */}
        <AppRoute
          path={routes.directions}
          component={components.directions}
          layout={LayoutGallery}
        />
        {/* 회사소개 */}
        <AppRoute
          path={routes.about}
          component={components.about}
          layout={LayoutGallery}
        />
        {/* 검색 */}
        <AppRoute
          path={routes.search + "/:search_va"}
          component={components.search}
          layout={LayoutGallery}
          exact={true}
        />
        {/* 메인 */}
        <AppRoute
          path={routes.home}
          component={components.home}
          layout={LayoutHome}
          exact={true}
          firstLoading={firstLoading}
          setLoading={setLoading}
        />
        <AppRoute
          path="*"
          component={components.error}
          layout={LayoutError}
        />
      </Switch>
    </Router>
  );
}

export default App;
