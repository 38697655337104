import React, { useState } from "react";
import { Link } from "react-router-dom";
import ExhibitionList from "./exhibitionList";
import ExhibitionSlide from "./exhibitionSlide";

export const ExhibitionIndex = () => {
  const [section, setSection] = useState("현재");

  /** 시제에 따른 클릭 이벤트 */
  const handleTense = (value) => {
    setSection(value);
  }

  /** 시제에 맞게 클랙스 부여 */
  const tenseClass = (section) => {
    const classMap = {
      "현재": "tense present",
      "과거": "tense past",
      "예정": "tense next",
    }

    return classMap[section];
  }

  return (
    <section className="art-wrapper">
      <h2 className="hidden-obj">전시작품</h2>
      <article className="w100p">
        <ExhibitionSlide />
      </article>
      <article>
        <div className="order-box">
          <ul className="list">
            <li
              className={section === "현재" ? "on" : ""}
              onClick={() => handleTense("현재")}
            >
              <Link to="#">현재</Link>
            </li>
            <li
              className={section === "과거" ? "on" : ""}
              onClick={() => handleTense("과거")}
            >
              <Link to="#">과거</Link>
            </li>
            <li
              className={section === "예정" ? "on" : ""}
              onClick={() => handleTense("예정")}
            >
              <Link to="#">예정</Link>
            </li>
          </ul>
          <div className="sort-box">
            <div className={tenseClass(section)}>
              <ExhibitionList
                exhibitSection={section}
              />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}
export default ExhibitionIndex;