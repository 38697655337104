import React, { useState, useEffect, useRef } from "react";
import Swiper, { Pagination, Autoplay, EffectFade } from "swiper";
import { iGalleryApi } from "../../../utils/api/api";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const IGALLERY = process.env.REACT_APP_PATH_IGALLERY;
const IMAGE = process.env.REACT_APP_PATH_IMAGE;

const IGalleryInfoPresenter = () => {
  const [galleryInfoData, setGalleryInfoData] = useState(null);
  const [slideData, setSlideData] = useState([]);
  const [swiperCurrentNumber, setSwiperCurrentNumber] = useState(1);
  const [swiperTotalCount, setSwiperTotalCount] = useState(1);
  const swipeElement = useRef(null);
  Swiper.use([Pagination, Autoplay, EffectFade]);

  useEffect(() => {
    /** GET 전시회 정보 */
    iGalleryApi.detailInfo()
      .then((response) => {
        const { status, data } = response;

        if (status === 200) {
          setGalleryInfoData(data.currentGallery);
        }
      })
      .catch((error) => {
        setGalleryInfoData(null);
        console.log("error", error);
      })

    /** GET 전시 작품 리스트 */
    iGalleryApi.allSlide()
      .then((response) => {
        const { status, data } = response;

        if (status === 200) {
          setSlideData(data.data);
        }
      })
      .catch((error) => {
        setSlideData([]);
        console.log("error", error);
      })
  }, []);

  /** 전시 작품 리스트 swiper */
  useEffect(() => {
    if (galleryInfoData !== null && slideData.length > 0) {
      swipeElement.current = new Swiper('#igallery-slide', {
        effect: 'fade',
        centeredSlides: true,
        slidesPerView: 1,
        loop: true,
        autoplay: true,
        allowTouchMove: false,
        on: {
          activeIndexChange: function () {
            setSwiperCurrentNumber(this.realIndex + 1);
            setSwiperTotalCount(this.slides.length);
          }
        }
      });

      return () => {
        swipeElement.current.destroy();
      };
    }
  }, [galleryInfoData, slideData]);

  return (
    <article className="w100p">
      <div className="gallery-info">
        {
          galleryInfoData !== null ? (
            <>
              <div className="photo swiper-container" id="igallery-slide">
                <ul className="swiper-wrapper">
                  {
                    slideData.length === 0 ? (
                      <li className='swiper-slide'>
                        <img
                          src='/images/sub/empty.png'
                          alt='empty'
                        />
                      </li>
                    ) : (
                      slideData.map(row => (
                        <li
                          key={row.image_se}
                          className="swiper-slide"
                        >
                          <img
                            src={IMAGE_URL + IGALLERY + IMAGE + row.image_lk}
                            alt={row.image_lk}
                          />
                          <div className="bg-img">
                            <img
                              src={IMAGE_URL + IGALLERY + IMAGE + row.image_lk}
                              alt={row.image_lk}
                            />
                          </div>
                        </li>
                      )
                    ))
                  }
                </ul>
              </div>
              <div className="info">
                <dl className="term">
                  <dt>{galleryInfoData.igallery_tt}</dt>
                  <dd>{galleryInfoData.start_string} ~ {galleryInfoData.end_string}</dd>
                </dl>
                <div className="page-count">
                  <div className="num">
                    <span className="current">{swiperCurrentNumber}</span>/
                    {swiperTotalCount}
                  </div>
                </div>
              </div>
            </>
          ) : (<></>)
        }
      </div>
    </article>
  )
}

export default IGalleryInfoPresenter;