import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../routes/routes";
import Swiper, { Autoplay, EffectFade, Scrollbar } from "swiper";
import { homeApi, iGalleryApi } from "../../../utils/api/api";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const HOME = process.env.REACT_APP_PATH_MAIN_BANNER;
const IMAGE = process.env.REACT_APP_PATH_IMAGE;

/** 홈 슬라이드 코드 */
const SLIDE_CODE = 1;

const HomeInfo = () => {
  const [slideData, setSlideData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [artistList, setArtistList] = useState([]);
  const [swiperCurrentNumber, setSwiperCurrentNumber] = useState(1);
  const [swiperTotalCount, setSwiperTotalCount] = useState(1);
  const [playToggle, setPlayToggle] = useState(false);
  const swipeElement = useRef(null);
  Swiper.use([Autoplay, EffectFade, Scrollbar]);

  useEffect(() => {
    getExhibitionInfo();
  }, []);

  /** 아트웍 슬라이드 업데이트 */
  useEffect(() => {
    if (slideData.length > 0) {
      swipeElement.current = new Swiper(".action-wrap", {
        slidesPerView: 1,
        loop: true,
        effect: "fade",
        simulateTouch: true,
        autoplay: {
          delay: 3400,
          disableOnInteraction: false,
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
        },
        on: {
          activeIndexChange: function () {
            setSwiperCurrentNumber(this.realIndex + 1);
            setPlayToggle(false); // 마우스 스와이프 시 재생으로 전환
          },
          slideChange : function () { // 재생 버튼 클릭 시 activeIndexChange에서 setPlayToggle값 리셋
            setTimeout(() => {
              if (swipeElement.current && swipeElement.current.autoplay) {
                swipeElement.current.autoplay.start();
              }
            }, 10);
          }
        }
      });

      return () => {
        swipeElement.current && swipeElement.current.destroy();
      };
    }
  }, [slideData]);

  const getExhibitionInfo = () => {
    /** GET 전시 중인 아트웍 슬라이드 리스트 */
    if (slideData !== null) {
      homeApi.getBannerSlide(SLIDE_CODE)
        .then((response) => {
          const { status, data } = response;

          if (status === 200) {
            setSlideData(data.data);
            setSwiperTotalCount(data.data.length);
          }
        })
        .catch(() => {
          setSlideData([]);
        })
    }

    /** GET 전시 정보 */
    if (galleryData !== null) {
      iGalleryApi.detailInfo()
        .then((response) => {
          const { status, data } = response;

          if (status === 200) {
            setGalleryData(data.currentGallery);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }

    /** GET 작가 이름 리스트 */
    if (artistList !== null) {
      iGalleryApi.detailArtist()
        .then((response) => {
          const { data } = response;

          setArtistList(data.data);
        })
        .catch((error) => {
          console.log(error)
        });
    }
  }

  /** 연월일 구분 */
  const galleryDate = (date) => {
    if (!date) {
      return;
    }

    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);

    return `${year}.${month}.${day}`;
  }

  /** 작가 리스트 표출 */
  const artistNameDiv = (name) => {
    if (!name) {
      return;
    }
    if (name.length >= 1) {
      return name;
    }
  }

  /** 재생, 정지 버튼 클릭 이벤트 */
  const handlePlay = () => {
    if (playToggle) {
      swipeElement.current.autoplay.start();
      
    } else {
      swipeElement.current.autoplay.stop();
    }
  }

  return (
    <>
      <div className="exhibition-info">
        <h2>
          <span>We</span>
          <span>Love</span>
          <span>Art.</span>
        </h2>
        <>
          {
            galleryData && (
              <>
                <div className="name">{galleryData.igallery_tt}</div>
                <div className="date">{galleryDate(galleryData.start_dt)} ~ {galleryDate(galleryData.end_dt)}</div>
                <ul className="author">
                  {
                    artistList && (
                      artistList.map((el, index) => (
                        <li
                          key={index}
                        >
                          {artistNameDiv(el.artist_nm)}
                        </li>
                      ))
                    )
                  }
                </ul>
              </>
            )
          }
        </>
        <div className="btn-area">
          <Link to={`${routes.exhibition}`} className="btn-detail">더 많은 전시작품 보러가기<i></i></Link>
        </div>
      </div>
      <>
        {
          slideData.length === 0 ? (
            <div className="animate-view">
              <div className="action-wrap">
                <ol className="action-img swiper-wrapper">
                  <li className="swiper-slide">
                    <img src="/images/sub/empty.png" alt="empty" />
                  </li>
                </ol>
              </div>
            </div>
          ) : (
            <div className="animate-view">
              <div className="action-wrap main-swiper swiper swiper-container swiper-initialized swiper-horizontal swiper-pointer-events">
                <ol className="action-img swiper-wrapper">
                  {
                    slideData && slideData.map((row) => (
                      <li
                        className="swiper-slide"
                        key={row.banner_se}
                      >
                        <span className="frame">
                          <figure>
                            <img
                              src={IMAGE_URL + HOME + IMAGE + row.image_lk}
                              alt={row.image_lk}
                            />
                          </figure>
                        </span>
                      </li>
                    ))
                  }
                </ol>
                <div className="swiper-scrollbar"></div>
                <div className="controller-box">
                  <div className="page-count">
                    <div className="current">{swiperCurrentNumber}</div> / {swiperTotalCount}
                  </div>
                  <div className="controller" onClick={() => {
                    handlePlay();
                    setPlayToggle((prev) => !prev);
                  }}>
                    {
                      playToggle ? (
                        <div className="start"><img src="/images/sub/btn_play_b.png" alt="시작 버튼" /></div>
                      ) : (
                        <div className="stop"><img src="/images/sub/btn_stop_b.png" alt="정지 버튼" /></div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </>
    </>
  )
}

export default HomeInfo;