import React, { useState } from 'react';
import ArtShopList from './artShopList';

const ArtShop = () => {
  const [original, setOriginal] = useState(true);
  const [artPoster, setArtPoster] = useState(false);
  const [goods, setGoods] = useState(false);
  const [filter, setFilter] = useState({
    board_sc_cd: 1,
    order: 'desc',
  });

  /** 아트샵 목록 구분 (Original, Poster, Goods) */
  const initailItems = () => {
    if (original) {
      setOriginal(false);
    }
    if (artPoster) {
      setArtPoster(false);
    }
    if (goods) {
      setGoods(false);
    }
  }

  /** 아트샵 목록 구분 클릭 이벤트 */
  const handleItemsKind = (kind, state) => {
    initailItems();
    if (kind === 1) {
      setOriginal(!state);
    }
    if (kind === 2) {
      setArtPoster(!state);
    }
    if (kind === 3) {
      setGoods(!state);
    }

    setFilter({ ...filter, board_sc_cd: kind });
  }

  /** 아트샵 목록 클릭 시 클래스 toggle 이벤트 */
  const itemKindClassOn = (state) => {
    return (state ? "on" : "");
  }

  /** select 조회 클릭 이벤트 */
  const handleOrder = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  }

  return (
    <section className="artshop-wrapper">
      <h2 className="hidden-obj">아트샵</h2>
      <article>
        <div className="order-box">
          <ul className="list">
            <li
              className={itemKindClassOn(original)}
              onClick={() => handleItemsKind(1, original)}
            >
              Original
            </li>
            <li
              className={itemKindClassOn(artPoster)}
              onClick={() => handleItemsKind(2, artPoster)}
            >
              Art Poster
            </li>
            <li
              className={itemKindClassOn(goods)}
              onClick={() => handleItemsKind(3, goods)}
            >
              Goods
            </li>
          </ul>
          <div className="select-area">
            <select
              name='order'
              value={filter.order}
              onChange={handleOrder}
            >
              <option value="DESC">최신순</option>
              <option value="ASC">오래된순</option>
            </select>
          </div>
          <div className="art-box">
            <div className="art-area">
              <ArtShopList
                filter={filter}
              />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}

export default ArtShop;