import React, { useState, useEffect } from 'react';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import { iGalleryApi } from '../../../utils/api/api';

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const IMAGE = process.env.REACT_APP_PATH_IMAGE;
const ARTIST = process.env.REACT_APP_PATH_ARTIST;

// 아이갤러리 Artists, Works 
const ArtistsList = ({ setArtiseNumber }) => {
  const [artistData, setArtistData] = useState([]);
  Swiper.use([Navigation, Pagination, Autoplay]);

  useEffect(() => {
    /** GET 아티스트 리스트 */
    if (artistData !== null) {
      iGalleryApi.detailArtist()
      .then((response) => {
        const { status, data } = response;

        if (status === 200) {
          setArtistData(data.data);
          setArtiseNumber(data.data[0].artist_se);
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
    }
  }, []);
  
  useEffect(() => {
    /** 작가 리스트 swiper */
    if (artistData.length > 0) {
      new Swiper('#artistBox', {
        slidesPerView: 1,
        autoHeight: true,
        loop: false,
        spaceBetween: 20,
        autoHeight: true,
        loopAdditionalSlides: 1,
        allowTouchMove: false,
        navigation: {
          nextEl: '.art-next',
          prevEl: '.art-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'fraction',
        },
        on: {
          activeIndexChange: function () {
            const changedArtistNumber = artistData[this.realIndex].artist_se;
            setArtiseNumber(changedArtistNumber);
          }
        }
      });
    }
  }, [artistData, setArtiseNumber]);

  return (
    <>
      <article>
        <div className='artist-box'>
          <h3>Artists</h3>
            {
              artistData.length === 0 ? (
                <div className='artist-wrapper'>
                  <div className='artist-wrap swiper-container'>
                    <div id="artistBox" className="artists">
                      <ul className='artist-list swiper-wrapper'>
                        <li className='swiper-slide'>
                          <div className='photo'>
                            <img
                              src='/images/common/basic.png'
                              alt='/images/common/basic.png'
                            />
                          </div>
                          <div className='artist-info'>
                            <div className='name'>
                              <span className='eng-name'>준비중입니다.</span>
                            </div>
                            <div className='email'></div>
                            <div className='tel'></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='artist-wrapper'>
                  <div className='artist-wrap swiper-container'>
                    <div id="artistBox" className="artists">
                      <ul className='artist-list swiper-wrapper'>
                        {
                          artistData && artistData.map((artist) => (
                            <li
                              className='swiper-slide'
                              key={artist.artist_se}
                            >
                              <div className='photo'>
                                <img
                                  src={IMAGE_URL + ARTIST + IMAGE + artist.image_lk}
                                  alt='작가 사진'
                                />
                              </div>
                              <div className='artist-info'>
                                <div className='name'>
                                  {artist.artist_nm}
                                  <span className='eng-name'>
                                    {artist.artist_eng_nm}
                                  </span>
                                </div>
                                <ul className='sns'>
                                  {
                                    artist.insta_lk === 0 ? (
                                      <li></li>
                                    ) : (
                                      <li className="instagram">
                                        <a
                                          href={artist.insta_lk}
                                          target='_blank'
                                          rel='noopener noreferrer'
                                        >
                                          인스타그램
                                        </a>
                                      </li>
                                    )
                                  }
                                </ul>
                              </div>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                    {
                      artistData.length > 1 &&
                      <>
                        <div className='swiper-button-prev art-prev'></div>
                        <div className='swiper-button-next art-next'></div>
                      </>
                    }
                    <div className='swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal'></div>
                  </div>
                </div>
              )
            }
        </div>
      </article>
    </>
  );
};

export default ArtistsList;
