import React, { useState, useEffect } from "react";
import GalleryInfo from "./galleryInfo";
// import GalleryIntro from "../components/pages/iGallery/galleryIntro"; 관리자에서 안 쓰는 기능 2024-01-23
import ArtistsList from "./artistsList";
import ArtworkList from "./artworkList";

const IGalleryMain = () => {
  const [artistNumber, setArtiseNumber] = useState(0);

  useEffect(() => {}, [artistNumber]);

  return (
    <section className="gallery-wrapper">
      <h2 className="hidden-obj">iGallery</h2>
      <GalleryInfo />
      {/* <GalleryIntro /> */}
      <ArtistsList 
        artistNumber={artistNumber}
        setArtiseNumber={setArtiseNumber}
      />
      <ArtworkList
        artistNumber={artistNumber}
        setArtiseNumber={setArtiseNumber}
      />
    </section>
  );
};

export default IGalleryMain;
