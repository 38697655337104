import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { routes } from '../../routes/routes';

const Header = ({ url }) => {
  const { search_va } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [separateHeader, setSeparateHeader] = useState(false);
  const [rootSearch, setRootSearch] = useState("");
  const [searchVa, setSearchVa] = useState("");
  const [btnSearch, setBtnSearch] = useState(false);
  const [btnHamburger, setBtnHamburger] = useState(false);
  const inputSearchRef = useRef(null);

  
  useEffect(() => {
    if (location.pathname) {
      window.scrollTo(0, 0);
      document.querySelector("body").classList.remove("none-scroll");
      document.getElementById("content").classList.remove("dim");
    }

    urlMatching();
    searchbtnHidden();
  }, [history.location.pathname]);

  /** header와 content 영역 분리(header에 border-bottom style 추가) */
  const urlMatching = () => {
    let urlArr = ["exhibitionDetail", "artshop", "artShopDetail", "notice", "about", "directions", "search"];

    const pathnameMatching = urlArr.some(url => location.pathname.includes(url));

    if (pathnameMatching) {
      setSeparateHeader(true);
    } else {
      setSeparateHeader(false);
    }
  }

  /** 검색 페이지일 경우 검색버튼 숨김 */
  const searchbtnHidden = () => {
    if (location.pathname === history.location.pathname && search_va) {
      setRootSearch("dp-none");
    } else {
      setRootSearch("");
    }
  }

  /** 네비게이션 검색 버튼 클릭 이벤트 */
  const handleHeaderSearch = () => {
    setBtnSearch((prev) => !prev);
    setSearchVa("");

    /** input 포커스 이벤트 */
    if (inputSearchRef.current) {
      inputSearchRef.current.focus();
    }

    /** input 포커스 아웃 시 키패드 숨김 처리 및 스크롤 제어 */
    if (btnSearch || (btnSearch && window.innerWidth < 1081)) {
      handleMobileKeyPad();
      document.querySelector("body").classList.remove("none-scroll");
      document.getElementById("content").classList.remove("dim");
    } else {
      document.querySelector("body").classList.add("none-scroll");
      document.getElementById("content").classList.add("dim");
    }
  }

  /** 검색창 입력 이벤트 */
  const handleValueChange = (event) => {
    setSearchVa(event.target.value);
  }

  /** 사용자 검색 시 키패드 숨김 처리 */
  const handleMobileKeyPad = () => {
    if (inputSearchRef.current) {
      inputSearchRef.current.blur();
    }
  }

  /** 사용자 입력 후 검색 버튼 클릭 이벤트 */
  const handleInputSearch = (event) => {
    if (event.target) {
      setBtnSearch((prev) => !prev);
      setSearchVa("");
      handleMobileKeyPad();
      document.querySelector("body").classList.remove("none-scroll");
      document.getElementById("content").classList.remove("dim");
    }
    if (searchVa === "") {
      alert("1");
    }
  }

  /** 검색 엔터 이벤트 */
  const onEnter = (event) => {
    if ((event.keyCode === 13 || event.key === "Enter") && searchVa !== "") {
      document.querySelector("body").classList.remove("none-scroll");
      document.getElementById("content").classList.remove("dim");
      history.push(`${routes.search}/${searchVa}`);
      setBtnSearch(false);
      setBtnHamburger(false);
      setSearchVa("");
      handleMobileKeyPad();
    }
  }

  /** 햄버거 메뉴 버튼 클릭 이벤트 */
  const handleHamburger = (event) => {
    if (event.target) {
      setBtnHamburger((prev) => !prev);

      if (btnHamburger) {
        document.querySelector("body").classList.remove("none-scroll");
      } else {
        document.querySelector("body").classList.add("none-scroll");
      }
    }
  }

  /** 모바일 메뉴 클릭 이벤트 */
  const mobileDepth = () => {
    setBtnHamburger(false);
    document.querySelector("body").classList.remove("none-scroll");
  }

  return (
    <header className={`${separateHeader ? "separate" : ""}`}>
      <div className="header-line">
        <h1>
          <Link to="/">
            <img src="/images/common/logo.png" alt="Alternate Text" />
          </Link>
        </h1>
        {/* 네비게이션 바 */}
        <nav>
          <ul>
            <li className={(url && url.includes(routes.exhibition)) ? "on" : ""}>
              <Link to={routes.exhibition}>전시작품</Link>
            </li>
            <li className={(url && url.includes(routes.igallery)) ? "on" : ""}>
              <Link to={routes.igallery}>i갤러리</Link>
            </li>
            <li className={(url && url.includes(routes.artshop)) ? "on" : ""}>
              <Link to={routes.artshop}>아트샵</Link>
            </li>
            <li className={(url && url.includes(routes.notice)) ? "on" : ""}>
              <Link to={routes.notice}>공지사항</Link>
            </li>
            <li className={(url && url.includes(routes.about)) ? "on" : ""}>
              <Link to={routes.about}>갤러리소개</Link>
            </li>
          </ul>
          <Link
            to="#"
            className={`btn btn-search ${rootSearch}${btnSearch ? "on" : ""}`}
            onClick={handleHeaderSearch}
          >
            검색
            <span></span>
            <span></span>
          </Link>

          <Link
            to="#"
            className={`btn btn-hamburger ${btnHamburger ? "on" : ""}`}
            onClick={handleHamburger}
          >
            메뉴
            <span></span>
            <span></span>
            <span></span>
          </Link>
        </nav>
      </div>
      {/* 검색창 */}
      <div className={`search-wrap ${btnSearch ? "on" : ""}`}>
        <div className="search-box">
          <div className="search">
            <input
              ref={inputSearchRef}
              type="search"
              placeholder="Search"
              className="ipt"
              value={searchVa}
              onChange={handleValueChange}
              onKeyUp={onEnter}
            />
            <Link
              to={routes.search + "/" + searchVa}
              className="btn-search"
              onClick={handleInputSearch}
            >
              검색
              <span></span>
              <span></span>
            </Link>
          </div>
        </div>
      </div>
      {/* 모바일 메뉴 */}
      <div className={`hamburger-wrap ${btnHamburger ? "on" : ""}`}>
        <ul className="menu-list">
          <li className={(url && url.includes(routes.exhibition)) ? "on" : ""}>
            <Link
              to={routes.exhibition}
              onClick={mobileDepth}
            >
              전시작품<span>Exhibition</span>
            </Link>
          </li>
          <li className={(url && url.includes(routes.igallery)) ? "on" : ""}>
            <Link
              to={routes.igallery}
              onClick={mobileDepth}
            >
              i갤러리<span>i Gallery</span>
            </Link>
          </li>
          <li className={(url && url.includes(routes.artshop)) ? "on" : ""}>
            <Link
              to={routes.artshop}
              onClick={mobileDepth}
            >
              아트샵<span>Artshop</span>
            </Link>
          </li>
          <li className={(url && url.includes(routes.notice)) ? "on" : ""}>
            <Link
              to={routes.notice}
              onClick={mobileDepth}
            >
              공지사항<span>Notice</span>
            </Link>
          </li>
          <li className={(url && url.includes(routes.about)) ? "on" : ""}>
            <Link
              to={routes.about}
              onClick={mobileDepth}
            >
              갤러리소개<span>Introduce</span>
            </Link>
          </li>
        </ul>
        {/* sns */}
        <ul className="sns">
          <li>
            <a href="https://www.instagram.com/ggisland_official/" target="_blank" rel="noopener noreferrer">
              <img src="/images/common/ic_instagram.png" alt="인스타그램" />
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;