import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { exhibitionApi } from "../../../utils/api/api";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const EXHIBITION = process.env.REACT_APP_PATH_EXHIBITION;
const IMAGE = process.env.REACT_APP_PATH_IMAGE;

export const ExhibitionDetail = () => {
  const { exhibits_se } = useParams();
  const [detailPost, setDetailPost] = useState(null);

  /** GET 전시 작품 아트웍 상세 정보 */
  useEffect(() => {
    if (detailPost === null) {
      exhibitionApi.detailPost(exhibits_se)
      .then((response) => {
        const { status, data } = response;
  
        if (status === 200) {
          setDetailPost(data.data[0]);
        }
      })
      .catch((error) => {
        setDetailPost(null);
        console.log(error);
      })
    }
  }, [exhibits_se]);

  return (
    <section className="art-wrapper detail">
      <h2 className="hidden-obj">전시작품</h2>
      <article>
        <div className="art-info">
          {
            detailPost &&
            <>
              <h3 className="art-name">{detailPost.exhibits_tt}</h3>
              <div className="name">{detailPost.artist_nm}</div>
              <div className="img-art">
                <p>
                  <img
                    src={IMAGE_URL + EXHIBITION + IMAGE + detailPost.thumbnail_lk}
                    alt={detailPost.thumbnail_lk}
                  />
                </p>
              </div>
              <div className="txt">
                {detailPost.work_yr}
                {
                  detailPost.size_if &&
                  ` / ${detailPost.size_if}`
                }
                {
                  detailPost.comment_if &&
                  ` / ${detailPost.comment_if}`
                }
              </div>
              <div className="txt-area" dangerouslySetInnerHTML={{ __html: detailPost.exhibits_ct }}></div>
            </>
          }
        </div>
      </article>
    </section>
  );

}

export default ExhibitionDetail;