import React, { useState, useEffect } from 'react';
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";

export const PaginationEx = ({
  dataCount,
  activePage,
  itemsCountPerPage,
  pageRangeDisplayed,
  onChange,
  hideFirstLastPages,
}) => {
  const [totalCount, SetTotalCount] = useState(0);

  useEffect(() => {
    SetTotalCount(dataCount);
  }, [dataCount]);

  return (
    <Pagination
      activePage={activePage}
      itemsCountPerPage={itemsCountPerPage}
      totalItemsCount={totalCount}
      pageRangeDisplayed={pageRangeDisplayed}
      prevPageText={''}
      nextPageText={''}
      onChange={onChange}
      hideFirstLastPages={hideFirstLastPages}
    />
  );
}

const Paging = ({ data, loading, error, activePage, itemsCountPerPage, pageRangeDisplayed, onChange, hideFirstLastPages }) =>
  loading ? (
    <span></span>
  ) : (
    <Pagination
      activePage={activePage}
      itemsCountPerPage={itemsCountPerPage}
      totalItemsCount={data ? Number(data[1].postCount) : 1}
      pageRangeDisplayed={pageRangeDisplayed}
      prevPageText={""}
      nextPageText={""}
      onChange={onChange}
      hideFirstLastPages={hideFirstLastPages}
    />
  );

Paging.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  activePage: PropTypes.number,
  itemsCountPerPage: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  onChange: PropTypes.func
}

export default Paging;