import React, { useEffect, useRef } from 'react';

const Directions = () => {
  const container = useRef(null); // 지도를 담을 영역의 DOM 레퍼런스

  useEffect(() => {
    // index.html에 명시된 카카오맵API appkey=9c23f66cf88be2d5566c85739e99f808
    if (window.kakao !== undefined) {
      const options = {
        // 지도를 생성할 때 필요한 기본 옵션
        center: new window.kakao.maps.LatLng(37.412047496255845, 127.14332296265643), //지도의 중심좌표.
        level: 2, // 지도의 레벨(확대, 축소 정도)
      };

      let map = new window.kakao.maps.Map(container.current, options); // 지도 생성 및 객체 리턴
      // 마커가 표시 될 위치
      let markerPosition = new window.kakao.maps.LatLng(
        37.412047496255845, 127.14332296265643
      );

      // 마커를 생성
      let marker = new window.kakao.maps.Marker({
        position: markerPosition,
      });

      marker.setMap(map);
    }
    else {
      console.log("카카오맵과의 연결이 끊겼습니다. 관리자에게 문의해주세요.")
    }

    function Holiday() {
      let txtBubbles = document.querySelectorAll('.txt-bubble span');
      let txtBubbleChild = document.querySelector('.txt-bubble .heart-child');
      let i = 0;

      function startPop() {
        let txtPop = setInterval(function () {
          if (i === txtBubbles.length) {
            clearInterval(txtPop);
            setTimeout(function () {
              stopPop();
            }, 1000);
          } else {
            let txtSpan = txtBubbles[i];
            txtSpan.classList.add('on');
            i++;
          }
        }, 200);
      }
      startPop();

      function stopPop() {
        for (let t = 0; t < txtBubbles.length; t++) {
          if (t === txtBubbles.length - 1) {
            startPop();
          } else {
            let resetOn = txtBubbles[t];
            resetOn.classList.remove('on');
            txtBubbleChild.classList.remove('on');
          }
          i = 0;
        }
      }
    }
    Holiday();
  }, []);

  return (
    <section className="directions-wrapper">
      <h2>아트<i><img src="/images/sub/ic_gg_b.png" alt="GG" /></i>갤러리 오시는길</h2>
      <article>
        <div className="map-box">
          <div className="gg-map">
            <div
              className="map"
              ref={container}
            ></div>
          </div>
          <dl>
            <dt>Location</dt>
            <dd>
              <h1>경기 성남시 분당구 벌말로 50번길 41 투아이센터 1층 </h1>
            </dd>
          </dl>
          <dl>
            <dt>Call</dt>
            <dd>
              <a href="tel:031-781-0236">070<i></i>4123<i></i>8817</a>
            </dd>
          </dl>
          <dl>
            <dt>Bussiness hours</dt>
            <dd>
              <span>화요일 - 일요일 (월요일 휴무)</span>
              <span>am 10:30 - pm 7:00</span>
            </dd>
          </dl>
          <div className="tip">
            <div className="img">
              <img src="/images/sub/ic_gg_on.png" alt="Art GG Gallery" />
            </div>
            <div className="txt-bubble">
              <p>
                <span className="">M</span>
                <span className="">O</span>
                <span className="">N</span>
                <span className="">D</span>
                <span className="">A</span>
                <span className="">Y</span>
                <span className="">O</span>
                <span className="">F</span>
                <span className="">F</span>
                <span className="heart heart-parent">
                  <span className="heart heart-child"></span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Directions;