import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const Error = () => {
  const [error, setError] = useState("");

  useEffect(() => {
    let userAgent = navigator.userAgent.toLowerCase();

    /** 접속한 기기에 따른 클래스 처리 */
    if (userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod')) {
      setError("IOS");
    } else {
      setError("glitch");
    }
  }, [])

  return (
    <div className={`error-box ${error}`}>
      <div className="img-area">
        <div className="img">
          <img src="/images/sub/bg_error.png" alt="Art GG Gallery error" />
        </div>
        <div className="txt-error"><span>error</span></div>
      </div>
      <dl>
        <dt>요청하신 페이지를 찾을 수 없습니다.</dt>
        <dd>페이지 주소가 정확하지 않거나 <span>페이지에 오류가 있어 요청하신 내용을 찾을 수 없습니다.</span></dd>
        <dd>
          <Link to="/" className="btn-back">
            메인 페이지로 가기<i></i>
          </Link>
        </dd>
      </dl>
    </div>
  );
};

export default Error;