import React, { useState, useEffect } from 'react';
import { routes } from "../../../routes/routes";
import { useHistory } from "react-router-dom";
import { exhibitionApi } from "../../../utils/api/api";
import { PaginationEx } from "../../common/pagination";
import { handleCheckImgSize } from "../../../utils/utils";

const PAGE_SIZE = 9;
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const EXHIBITION = process.env.REACT_APP_PATH_EXHIBITION;
const THUMBNAIL = process.env.REACT_APP_PATH_THUMBNAIL;

const ExhibitionList = ({ exhibitSection }) => {
  const pastYearList = [];
  const [isLoading, setLoading] = useState(false);
  const [artWorkList, setArtWorkList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDataCnt, setTotalDataCnt] = useState(1);
  const [yearList, setYearList] = useState(pastYearList);
  const [selectYear, setSelectYear] = useState(0);
  const [tempYearArr, setTempYearArr] = useState([]);
  const history = useHistory();

  /** 과거 탭 클릭 시 */
  useEffect(() => {
    if (exhibitSection === "과거") {
      getSelectYear();
    }
    else {
      setYearList([]);
    }

    getExhibitsMainList(currentPage, selectYear);
  }, [currentPage, exhibitSection]);

  /** 과거 연도 리스트의 데이터, 배열로 변환 */
  useEffect(() => {
    for (let i = 0; i < yearList.length; i++) {
      tempYearArr.push(yearList[i]);
    }
    setTempYearArr(tempYearArr);
  }, [yearList]);

  /** GET 작품 리스트 */
  const getExhibitsMainList = (currentPage, selectYear = 0) => {
    setLoading(true);
    // 현재,예정 전시작품은 selectYear = 0, 과거 전시작품 에는 selectYear > 0
    exhibitionApi.ExhibitsMainList(currentPage, PAGE_SIZE, exhibitSection, selectYear)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setArtWorkList(data.data);
          setTotalDataCnt(Number(data.count));
        }
      })
      .catch(() => {
        setArtWorkList([]);
        setTotalDataCnt(0);
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /** GET 과거작품 연도 리스트 가져오기 */
  const getSelectYear = () => {
    exhibitionApi.allYear()
      .then((response) => {
        const { status, data } = response;

        if (status === 200) {
          setYearList(data);
        }
      })
      .catch(() => {
        setYearList([]);
      })
  }

  /** 페이지네이션 클릭 이벤트 */
  const handlePageChange = (currentPage) => {
    setCurrentPage(currentPage);
    getExhibitsMainList(currentPage);
  }

  /** 과거 연도 select 클릭 이벤트 */
  const handleYearChange = (currentYear) => {
    setCurrentPage(1);
    setSelectYear(currentYear);
    getExhibitsMainList(1, currentYear);
  }

  /** 시제에 따른 날짜 컴포넌트 */
  const TenseData = () => {
    if (exhibitSection === "현재" || exhibitSection === "예정") {
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const monthNumber = (new Date().getMonth());

      return (
        <h3 className="tit-data">{`${new Date().getFullYear()} ${monthNames[monthNumber]}`}</h3>
      )
    } else if (exhibitSection === "과거") {
      return (
        <select
          value={selectYear}
          onChange={(event) => handleYearChange(event.target.value)}
        >
          {
            yearList.length !== 0
            && Object.values(yearList).map((row) => (
              <option
                key={row.year}
                value={row.year}
              >
                {row.year}
              </option>
            ))
          }
        </select>
      )
    }
  }

  return (
    <>
      <TenseData />
      <div className="art-area">
        { isLoading && <></> }
        {
          !isLoading && artWorkList.length === 0
            ? <p className="no-data">{exhibitSection} 전시작품이 없습니다.</p>
            : (
              <ul className="art-list">
                {
                  artWorkList.map((row) => (
                    <li
                      key={row.exhibits_se}
                      onClick={() => history.push(`${routes.exhibitionDetail}/${row.exhibits_se}`)}
                    >
                      <div className="frame">
                        <span>
                          <img
                            onLoad={handleCheckImgSize}
                            src={IMAGE_URL + EXHIBITION + THUMBNAIL + row.thumbnail_lk}
                            alt=""
                          />
                        </span>
                      </div>
                      <dl>
                        <dt>{row.exhibits_tt}</dt>
                        <dd>{row.artist_nm}</dd>
                      </dl>
                    </li>
                  ))
                }
              </ul>
            )
        }
        {
          artWorkList.length > 0 &&
          <PaginationEx
            dataCount={totalDataCnt}
            activePage={currentPage}
            itemsCountPerPage={PAGE_SIZE}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
            hideFirstLastPages={true}
          />
        }
      </div>
    </>
  )
}

export default ExhibitionList;