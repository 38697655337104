import React, { useState, useEffect, useRef } from "react";

const Loading = ({ firstLoading, setLoading }) => {
  const [yellowG, setYellowG] = useState("");
  const [iconHeart, setIconHeart] = useState("");
  const artTextRef = useRef(null);
  const galleryTextRef = useRef(null);

  /** 텍스트 art 각각 알파벳 이미지 */
  const artTextItems = [
    "alphabet_a01",
    "alphabet_r",
    "alphabet_t"
  ];

  /** 텍스트 gallery 각각 알파벳 이미지 */
  const galleryTextItems = [
    "alphabet_g",
    "alphabet_a02",
    "alphabet_l",
    "alphabet_l",
    "alphabet_e",
    "alphabet_r",
    "alphabet_y",
  ]

  useEffect(() => {
    const loading = () => {
      document.querySelector("body").classList.add("loading", "none-scroll");

      setYellowG("on");
      let artTextNumber = 0;
      let galleryTxtNumber = 0;
      
      // art gg gallery 글자 표출 이벤트
      if (!yellowG) {
        // 노란색 G가 파란색 G에게 기댈 때 실행
        let artText = setInterval(function () {
          if (artTextNumber === (artTextRef.current.children && artTextRef.current.children.length)) {
            clearInterval(artText);
          } else {
            let firstSpanOn = artTextRef.current.children[artTextNumber];
            firstSpanOn.classList.add("on");
            artTextNumber++;
            return artTextNumber;
          }

          // .txt01 자식요소의 span들이 on 클래스를 전부 갖을 때 실행
          if (artTextNumber === 3) {
            let galleryText = setInterval(function () {
              if (galleryTxtNumber === galleryTextRef.current.children.length) {
                clearInterval(galleryText);
                setIconHeart("on");
              } else {
                let lastSpanOn = galleryTextRef.current.children[galleryTxtNumber];
                lastSpanOn.classList.add("on");
                galleryTxtNumber++;
              }
            }, 140);
          }
        }, 240);
      }
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);

    if (firstLoading === false) {
      loading();

      setTimeout(() => {
          setLoading(true); // 페이지 최초로딩 firstLoading = true로 변경
          document.querySelector("body").classList.remove("loading", "none-scroll");
        }, 1500 * 3);
    }
  }, []);

  return (
    <div id="load" className="load">
      <div className="expression ">
        <h2 className="hidden-obj">로딩중</h2>
        <div id="art" className={`txt txt01 `} ref={artTextRef}>
          {
            artTextItems.map((item, index) => (
              <span
                key={index}
              >
                <img src={`/images/sub/${item}.png`} alt={item} />
              </span>
            ))
          }
        </div>
        <div className="img-area">
          <div className={`ele-amt ${yellowG}`}>
            <img src="/images/sub/bg_loading01.png" alt="G" />
          </div>
          <div className="ele-fixed">
            <img src="/images/sub/bg_loading02.png" alt="G" />
          </div>
        </div>
        <div className="txt txt02" ref={galleryTextRef}>
          {
            galleryTextItems.map((item, index) => (
              <span
                key={index}
              >
                <img src={`/images/sub/${item}.png`} alt={item} />
              </span>
            ))
          }
        </div>
        <div className={`heart ${iconHeart}`}>
          <img src="/images/sub/bg_heart.png" alt="하트이미지" />
        </div>
      </div>
    </div>
  );
};

export default Loading;
