import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { noticeApi } from "../../../utils/api/api";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const NOTICE = process.env.REACT_APP_PATH_NOTICE;
const IMAGE = process.env.REACT_APP_PATH_IMAGE;

const NoticeDetail = () => {
  const { board_se } = useParams();
  const [boardNum, setBoardNum] = useState(board_se);
  const [detailPost, setDetailPost] = useState(null);
  const [detailImage, setDetailImage] = useState(null);
  const [loading, setLoading] = useState(true);

  /** 공지사항 리스트 업데이트 */
  useEffect(() => {
    getNewsDetail();
  }, []);

  /** GET 공지사항 리스트 */
  const getNewsDetail = async () => {
    try {
      const [detailPostResponse, detailImageResponse] = await Promise.all([
        noticeApi.detailPost(boardNum),
        noticeApi.detailImage(boardNum)
      ]);

      const detailPostData = detailPostResponse.data;
      const detailImageData = detailImageResponse.data;

      setDetailPost(detailPostData);
      setDetailImage(detailImageData);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }

  /** 이전, 다음 버튼 클릭 이벤트 */
  const handleOtherNews = async (boardNum) => {
    if (boardNum !== 0) {
      setBoardNum(boardNum);
      getNewsDetail();
    }
  }

  return (
    <section className="notice-wrapper detail">
      <h2 className="hidden-obj">갤러리소식</h2>
      <article>
        <div className="notice-box">
          {
            !loading && (
              <>
                {
                  detailPost !== null && (
                    detailPost.map((post, index) => (
                      <div className="notice-area" key={index}>
                        <div className="info">
                          <div className="tit-area">
                            <div className={"kind " + (post.is_notice ? "notice" : "")}>
                              {post.board_sc_tt}
                            </div>
                            <div className="tit">{post.board_tt}</div>
                          </div>
                          <div className="date">{post.record_dt}</div>
                        </div>
                        <div className="contents">
                          <div className="img-cut">
                            {
                              detailImage && detailImage.map((image, index) => (
                                <figure key={index}>
                                  <img src={IMAGE_URL + NOTICE + IMAGE + image.image_lk} alt="img" />
                                </figure>
                              ))
                            }
                          </div>
                          <div dangerouslySetInnerHTML={{ __html: post.board_ct }}></div>
                        </div>
                      </div>
                    ))
                  )
                }
              </>
            )
          }
          <ul className="pagination type02">
            <li className="prev">
              <Link
                to="#"
                onClick={() => { detailPost && handleOtherNews(detailPost[0].prev_board_se) }}
              >
                이전글
              </Link>
            </li>
            <li className="next">
              <Link
                to="#"
                onClick={() => { detailPost && handleOtherNews(detailPost[0].next_board_se) }}
              >
                다음글
              </Link>
            </li>
          </ul>
        </div>
      </article>
    </section>
  )
}

export default NoticeDetail;