import React from 'react';
import Header from "../components/common/header";
import Loading from '../components/common/loading';

const LayoutHome = ({ children, firstLoading, setLoading }) => {

  return (
    <div id='wrap'>
      <div id="container">
        {
          !firstLoading ? (
            <Loading
              firstLoading={firstLoading}
              setLoading={setLoading}
            />
          ) : (
            <div id="content" className="main">
              <Header />
              {children}
            </div>
          )
        }
      </div>
    </div>
  );
}

export default LayoutHome;