import React from "react";
import HomeInfo from "./homeInfo";

const MainIndex = () => {

  return (
    <section className="main-wrapper">
      <article>
        <HomeInfo />
      </article>
    </section>
  );
};

export default MainIndex;
