
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import { handleCheckImgSize } from '../../../utils/utils';
import { iGalleryApi } from '../../../utils/api/api';

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const THUMBNAIL = process.env.REACT_APP_PATH_THUMBNAIL;
const EXHIBITION = process.env.REACT_APP_PATH_EXHIBITION;

const ArtworkList = ({ artistNumber }) => {
  const history = useHistory();
  const [artworksList, setArtworkList] = useState([]);

  /** 해당 아티스트의 아트웍 업데이트 */
  useEffect(() => {
    getArtworkList(artistNumber);
  }, [artistNumber]);

  /** GET 해당 아티스트의 아트웍 */
  const getArtworkList = (artistNumber) => {
    iGalleryApi.allWorks(artistNumber)
      .then((response) => {
        const { status, data } = response;

        if (status === 200) {
          setArtworkList(data.data);
        }
      })
  }

  return (
    <article>
      <div className='work-box'>
        <h3>Works</h3>
        {
          artworksList.length === 0 ? (
            <p className='no-data'>등록된 작품이 없습니다.</p>
          ) : (
            <ul className='work-list'>
              {
                artworksList && artworksList.map((row) => (
                  <li
                    key={row.exhibits_se}
                    onClick={() => history.push(`${routes.exhibitionDetail}/${row.exhibits_se}`)}
                  >
                    <div className='frame'>
                      <span>
                        <img
                          onLoad={handleCheckImgSize}
                          src={
                            IMAGE_URL +
                            EXHIBITION +
                            THUMBNAIL +
                            row.thumbnail_lk
                          }
                          alt='아이갤러리 작품 사진'
                        />
                      </span>
                    </div>
                    <dl>
                      <dt>{row.exhibits_tt}</dt>
                      <dd>{row.artist_nm}</dd>
                    </dl>
                  </li>
                ))
              }
            </ul>
          )
        }
      </div>
    </article>
  )
}

export default ArtworkList;