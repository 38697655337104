import React from 'react';

const LayoutError = ({ children }) => {
  return (
    <div id='wrap' className='error'>
      <div id="container">
        <div id="content">
          {children}
        </div>
      </div>
    </div>
  );
}

export default LayoutError;