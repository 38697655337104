import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { routes } from '../../../routes/routes';
import { handleCheckImgSize } from "../../../utils/utils";
import { searchApi } from "../../../utils/api/api"

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const ARTSHOP = process.env.REACT_APP_PATH_ART_SHOP;
const EXHIBITION = process.env.REACT_APP_PATH_EXHIBITION;
const THUMBNAIL = process.env.REACT_APP_PATH_THUMBNAIL;

const SearchResult = ({ searchVa, setSearchValue, setResultCount }) => {
  const history = useHistory();
  const [artwork, setArtwork] = useState(null);
  const [allItems, setAllItems] = useState(null);
  const [loading, setLoading] = useState(true);

  /** 작가의 아트웍 및 굿즈 리스트 업데이트 */
  useEffect(() => {
    getSearchArtWorkList(searchVa);
  }, [searchVa]);

  /** GET 아트샵, 작품 검색 API 병렬 호출 */
  const getSearchArtWorkList = async (searchVa) => {
    try {
      const [worksResponse, itemsResponse] = await Promise.all([
        searchApi.allWorks(searchVa),
        searchApi.allItems(searchVa)
      ]);

      const worksData = worksResponse.data;
      const itemsData = itemsResponse.data;

      setArtwork(worksData);
      setAllItems(itemsData);

      setResultCount(parseInt(worksData[1].postCount) + parseInt(itemsData[1].postCount));
      setSearchValue(searchVa);
    } catch (error) {
      setArtwork(null);
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {
        !loading &&
        <>
          {
            (artwork && artwork[1].postCount !== '0') && (
              <div className="result-area">
                <h4>작품({artwork[1].postCount})</h4>
                <div className="art-area">
                  <ul className="art-list">
                    {
                      artwork &&
                      artwork[0].map((result, index) => (
                        <li
                          key={index}
                          onClick={() => history.push(`${routes.exhibitionDetail}/${result.exhibits_se}`)}
                        >
                          <div className="frame">
                            <span>
                              <img
                                onLoad={handleCheckImgSize}
                                src={IMAGE_URL + EXHIBITION + THUMBNAIL + result.thumbnail_lk} alt=""
                              />
                            </span>
                          </div>
                          <dl>
                            <dt>{result.exhibits_tt}</dt>
                            <dd>{result.artist_nm}</dd>
                          </dl>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            )
          }
          {
            (allItems && allItems[1].postCount !== '0') && (
              <div className="result-area">
                <h4>아트샵({allItems[1].postCount})</h4>
                <div className="art-area">
                  <ul className="art-list">
                    {
                      allItems &&
                      allItems[0].map((result, index) => (
                        <li
                          key={index}
                          onClick={() => history.push(`${routes.artshopDetail}/${result.board_sc_cd}/${result.artist_se}/${result.board_se}`)}
                        >
                          <div className="frame">
                            <span>
                              <img
                                onLoad={handleCheckImgSize}
                                src={IMAGE_URL + ARTSHOP + THUMBNAIL + result.thumbnail_lk}
                                alt={result.thumbnail_lk}
                              />
                            </span>
                          </div>
                          <dl>
                            <dt>{result.board_tt}</dt>
                            <dd>{result.artist_nm}</dd>
                          </dl>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            )
          }
          {
            (artwork && artwork[1].postCount === '0') && (allItems && allItems[1].postCount === '0') && (
              <p className="no-data">검색된 결과가 없습니다.</p>
            )
          }
        </>
      }
    </>
  )
}

export default SearchResult;