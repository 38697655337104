import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
  
  // select 패밀리 사이트 클릭 이벤트
  const handleSelectChange = (event) => {
    const { value } = event.target;
    if (value !== '') {
      window.open(value);
    }
  }

  return (
    <footer>
      <div className="footer-area">
        <div className="info01">
          <ul className="fnb">
            <li><Link to="/about">갤러리소개</Link></li>
            <li><Link to="/directions">오시는길</Link></li>
          </ul>
          <address>경기 성남시 분당구 벌말로 50번길 41 투아이센터 1층</address>
          <p>Bigglz. All rights reserved.</p>
        </div>
        <div className="info02">
          <dl>
            <dt>Bussiness hours</dt>
            <dd>TUE- SUN am 10:30 ~ pm 7:00</dd>
          </dl>
          <dl>
            <dt>Contact</dt>
            <dd>070-4123-8817</dd>
          </dl>
        </div>
        <div className="info03">
          <select onChange={handleSelectChange} value={""}>
            <option hidden value="">FAMLIY SITE</option>
            <option value="https://www.bigglz.com/">bigglz</option>
          </select>
        </div>
        <ul className="sns">
          <li>
            <a href="https://www.instagram.com/art_gg_gallery?igsh=MTRxNGJjYzExeDdocA==" target="_blank" rel="noopener noreferrer">
              <img src="/images/common/ic_instagram.png" alt="인스타그램" />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;