import React, { useState, useRef } from 'react';
import { Link, useParams } from "react-router-dom";
import SearchResult from './searchResult';

const Search = () => {
  const { search_va } = useParams();
  const [searchVa, setSearchVa] = useState(search_va);
  const [tempValue, setTempValue] = useState(search_va);
  const [resultCount, setResultCount] = useState(0);
  const inputSearchRef = useRef(null);
  
  /** 검색창 입력값 이벤트 */
  const handleChange = async (event) => {
    setTempValue(event.target.value);
  }

  /** 검색 버튼 클릭 이벤트 */
  const handleSearchAgain = async () => {
    if (tempValue !== null) {
      setSearchVa(tempValue)
    }
  }

  /** 검색된 결과의 총 갯수 */
  const handleResultCount = async (count) => {
    setResultCount(count);
  }

  /** 검색된 결과 리스트 */
  const handleSearchValue = async (value) => {
    setSearchVa(value)
  }

  /** 사용자 검색 시 키패드 다운 이벤트 */
  const handleMobileKeyPad = () => {
    if (inputSearchRef.current) {
      inputSearchRef.current.blur();
    }
  }

  /** 검색 버튼 키보드 엔터 이벤트 */
  const handleEnter = async (event) => {
    if (event.key === "Enter") {
      handleSearchAgain();
      handleMobileKeyPad();
    }
  }

  return (
    <section className="search-wrapper">
      <h2 className="hidden-obj">검색결과</h2>
      <article>
        <h3>
          '{searchVa}' 검색결과 <span>{resultCount}</span>건
        </h3>
        <div className="search-box">
          <div className="search">
            <input
              ref={inputSearchRef}
              type="search"
              placeholder="Search"
              className="ipt"
              onChange={handleChange}
              onKeyDown={handleEnter}
            />
            <Link
              to="#"
              className="btn-search"
              onClick={handleSearchAgain}
            >
              검색
              <span></span>
              <span></span>
            </Link>
          </div>
        </div>
        <div className="result-box">
          <SearchResult
            searchVa={searchVa}
            setSearchValue={handleSearchValue}
            setResultCount={handleResultCount}
          />
        </div>
      </article>
    </section>
  )
}

export default Search;