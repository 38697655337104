import React, { useState, useEffect, useRef } from "react";
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
import { Link } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { exhibitionApi } from "../../../utils/api/api";
import { handleCheckImgSize } from "../../../utils/utils";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const EXHIBITION = process.env.REACT_APP_PATH_EXHIBITION;
const THUMBNAIL = process.env.REACT_APP_PATH_THUMBNAIL;

const ExhibitionSlide = () => {
  const [exhibitionSlide, setEhibitionSlide] = useState([]);
  const [swiperCurrentNumber, setSwiperCurrentNumber] = useState(1);
  const [swiperTotalCount, setSwiperTotalCount] = useState(1);
  const [playToggle, setPlayToggle] = useState(false);
  const swipeElement = useRef(null);
  Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

  useEffect(() => {
    getExhibitionSlide();
  }, []);

  /** 전시 중인 아트웍 리스트 swiper */
  useEffect(() => {
    if (exhibitionSlide.length > 0) {
      swipeElement.current = new Swiper('.art-swiper', {
        spaceBetween: 50,
        autoWidth: true,
        centeredSlides: true,
        allowTouchMove: false,
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 5000,
        },
        breakpointsInverse: true,
        effect: 'fade',
        navigation: {
          nextEl: '.art-next',
          prevEl: '.art-prev',
        },
        on: {
          activeIndexChange: function () {
            setSwiperCurrentNumber(this.realIndex + 1);
          }
        }
      });

      return () => {
        swipeElement.current.destroy();
      }
    }
  }, [exhibitionSlide]);

  /** GET 전시 중인 아트웍 리스트 */
  const getExhibitionSlide = () => {
    exhibitionApi.allSlide()
      .then((response) => {
        const { status, data } = response;

        if (status === 200) {
          setEhibitionSlide(data.data);
          setSwiperTotalCount(data.data.length);
        }
      })
      .catch(() => {
        setEhibitionSlide([]);
      })
  }

  /** 재생, 정지 버튼 클릭 이벤트 */
  const handlePlay = () => {
    if (playToggle) {
      swipeElement.current.autoplay.start();
    } else {
      swipeElement.current.autoplay.stop();
    }
  }

  return (
    <>
      {
        exhibitionSlide.length === 0 ? (
            <div className="art-box">
              <div className="action-wrap art-swiper art-area swiper swiper-container swiper-coverflow swiper-3d swiper-initialized swiper-horizontal swiper-pointer-events">
                <ul className="action-img art-list swiper-wrapper">
                  <li className="swiper-slide">
                    <div className="art">
                      <div className="frame">
                        <span>
                          <img src="/images/sub/empty.jpg" alt="empty.jpg" />
                        </span>
                      </div>
                      <dl>
                        <dt>준비 중입니다</dt>
                        <dd></dd>
                      </dl>
                    </div>
                    <div className="bg-img">
                      <span><img src="/images/sub/empty.jpg" alt="empty.jpg" /></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="art-box" ref={swipeElement}>
              <div className="action-wrap art-swiper art-area swiper swiper-container swiper-coverflow swiper-3d swiper-initialized swiper-horizontal swiper-pointer-events">
                <ul className="action-img art-list swiper-wrapper">
                  {
                    exhibitionSlide && exhibitionSlide.map(row => (
                      <li
                        className="swiper-slide"
                        key={row.exhibits_se}
                      >
                        <div className="art">
                          <div className="frame">
                            <div>
                              <figure>
                                <img
                                  onLoad={handleCheckImgSize}
                                  src={IMAGE_URL + EXHIBITION + THUMBNAIL + row.thumbnail_lk}
                                  alt=""
                                />
                              </figure>
                            </div>
                          </div>
                          <div className="info">
                            <div className="art-info">
                              <div className="info-name">
                                <span className="name">{row.artist_nm}</span>{row.exhibits_tt}
                              </div>
                              <div className="info-txt">
                                {row.work_yr} / {row.size_if} / {row.comment_if}
                              </div>
                            </div>
                            <div className="btn-area">
                              <Link
                                to={`${routes.exhibitionDetail}/${row.exhibits_se}`}
                                className="btn-detail"
                              >
                                <i></i>작품 자세히 보기
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="bg-img">
                          <span><img src={IMAGE_URL + EXHIBITION + THUMBNAIL + row.thumbnail_lk} alt="" /></span>
                        </div>
                      </li>
                    ))
                  }
                </ul>
                {
                  exhibitionSlide.length > 1 &&
                  <>
                    <div
                      className='swiper-button-prev art-prev'
                      onClick={() => {
                        swipeElement.current.autoplay.start();
                        setPlayToggle(false);
                      }}
                    ></div>
                    <div
                      className='swiper-button-next art-next'
                      onClick={() => {
                        swipeElement.current.autoplay.start();
                        setPlayToggle(false);
                      }}
                    ></div>
                  </>
                }
                <div className="controller-box">
                  <div className="page-count">
                    <div className="current">{swiperCurrentNumber}</div> / {swiperTotalCount}
                  </div>
                  <div
                    className="controller"
                    onClick={() => {
                      handlePlay();
                      setPlayToggle((prev) => !prev);
                    }}
                  >
                    {
                      playToggle ? (
                        <div className="start"><img src="/images/sub/btn_play_w.png" alt="시작 버튼" /></div>
                      ) : (
                        <div className="stop"><img src="/images/sub/btn_stop_w.png" alt="정지 버튼" /></div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          )
      }
    </>
  );
};

export default ExhibitionSlide;