
import React from 'react';
import Directions from "./directions";

const AboutIndex = () => {

  return (
    <>
      <section className="about-wrapper">
        <h2 className="hidden-obj">회사소개</h2>
        <article>
          <div className="introduce-box">
            <h3>Our gallery</h3>
            <div className="txt-box box1">
              <div className="tit">
                <span>Art <i><img src="/images/sub/ic_gg_b.png" alt="GG" /></i> gallery는</span>
                <span><span className="deco">다양한 작가들의 예술작품을</span></span>
                <span>전시하고 있습니다.</span>
              </div>
              <div className="txt">
                <span>아트지지갤러리는 2021년에 출발한 신생 갤러리로서</span>
                <span>예술이 우리의 삶을 풍요롭게 해준다는 믿음을 가지고</span>
                <span>전시기획의 장을 열었습니다.</span>
              </div>
              <div className="txt-eng">
                <span>Art gg gallery exhibits abundant artworks by various unique artists.</span>
                <span>Founded in 2021, Art gg gallery is a new gallery</span>
                <span>that believes in the power of art enriches our lives.</span>
                <span>With this belief, we opened the stage for exhibition planning.</span>
              </div>
            </div>
            <div className="txt-box box2">
              <div className="txt-area">
                <div className="tit">
                  <span>살아 숨 쉬는</span>
                  <span><span className="deco">즐거움의 장소</span>로</span>
                  <span>초대합니다.</span>
                </div>
                <div className="txt">
                  <span>Art gg gallery는 한국미술의 현재를 책임지고 활발한</span>
                  <span>활동을 전개하고 있는 중견작가부터 미래를 창출할 혁신적인</span>
                  <span>신진작가까지 폭넓게 소개하는 자유 공간으로 예술문화의 흐름을</span>
                  <span>이해하고 아름다움을발견하는, 살아 숨 쉬는 즐거움의 장소로</span>
                  <span>만들어가고자 합니다.</span>
                </div>
                <div className="txt-eng">
                  <span>This exhibition place will be an alive and joyful space,</span>
                  <span>where people can enjoy and understand the current art culture</span>
                  <span>while discovering the beauty of art. With this vision,</span>
                  <span>Art gg gallery will introduce a wide range of art genre</span>
                  <span>and present diverse artists who are young and talented</span>
                  <span>as well as well known artists.</span>
                </div>
              </div>
            </div>
            <div className="txt-box box3">
              <div className="tit">
                <span>아트 <i><img src="/images/sub/ic_gg_b.png" alt="GG" /></i>갤러리는</span>
                <span><span className="deco">다르게, 또 같이</span>의 문화를</span>
                <span>추구합니다.</span>
              </div>
              <div className="txt">
                <span>Art gg gallery는 “다르게, 또 같이”의 문화를 추구하며 항상</span>
                <span>새로움을 향한 열린 사고와 함께 Good to Great한</span>
                <span>작품들 만을 콜렉션한다는 자세로 더욱더 밝은</span>
                <span>미래에 초점을 두는 갤러리로 존재하고 싶습니다.</span>
              </div>
              <div className="txt-eng">
                <span>Art gg gallery pursues the motto of "being different</span>
                <span>and doing together" with open-minded attitude</span>
                <span>and collecting "good to great“ artworks, focusing on</span>
                <span>being one of a bright future gallery in Korea.</span>
              </div>
            </div>
          </div>
        </article>
      </section>
      <Directions />
    </>
  );
};

export default AboutIndex;