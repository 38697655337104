import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { noticeApi } from "../../../utils/api/api";
import Pagination from "../../common/pagination";

const PAGE_SIZE = 6;

const Notice = () => {
  const history = useHistory();
  const [allPost, setAllPost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  /** 공지사항 리스트 업데이트 */
  useEffect(() => {
    getNoticeList(currentPage);
  }, [currentPage]);

  /** GET 공지사항 리스트 */
  const getNoticeList = async (pageNumber) => {
    await noticeApi.allPost(pageNumber)
      .then((response) => {
        const { status, data } = response;

        if (status === 200) {
          setAllPost(data);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setError("Error")
      })
      .finally(() => {
        setLoading(false);
      })
  }

  /** 현재 페이지 클릭 이벤트 */
  const handlePageChange = (currentPage) => {
    setCurrentPage(currentPage);
    getNoticeList(currentPage);
  }

  /** 상세 페이지 클릭 이벤트 */
  const handleDetailPage = (boardSe) => {
    history.push(`${routes.noticeDetail}/${boardSe}`);
  }

  return (
    <section className="notice-wrapper">
      <h2 className="hidden-obj">Notice</h2>
      <article>
        <div className="notice-box" key="notice">
          {
            loading ? (
              <></>
            ) : (
              <div className="notice-area">
                {
                  <>
                    <div className="thd">
                      <div>번호</div>
                      <div>제목</div>
                      <div>날짜</div>
                    </div>
                    <ul className="notice-list">
                      {
                        allPost && allPost[0].length > 0 && (
                          allPost[0].map((post, index) => (
                            <li
                              key={index}
                              onClick={() => handleDetailPage(post.board_se)}
                            >
                              <div className="num">
                                {allPost[1].postCount - index - ((currentPage - 1) * PAGE_SIZE)}
                              </div>
                              <div className="tit-area">
                                <div className={"kind " + (post.is_notice ? 'notice' : '')}>
                                  {post.board_sc_tt}
                                </div>
                                <div className="tit">{post.board_tt}</div>
                              </div>
                              <div className="date">{post.record_dt}</div>
                            </li>
                          ))
                        )
                      }
                    </ul>
                  </>
                }
              </div>
            )
          }
          <Pagination
            data={allPost}
            loading={loading}
            error={error}
            activePage={currentPage}
            itemsCountPerPage={PAGE_SIZE}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            hideFirstLastPages={true}
          />
        </div>
      </article>
    </section>
  )
}

export default Notice;